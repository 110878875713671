
<i18n locale="th" lang="yaml" >
page.title : "{name} :: ข้อมูลผู้ใช้งาน"

tab.user_info : "ข้อมูลผู้ใช้งาน"
</i18n>
<template>
	<div class="page page-padding">
		<div class="mylayout-left-right responsive">
			<div class="mylayout-left">
				<UserCard
					class="user-card"
					:loading="pageLoading"
					:user="user"
					:line-account="lineAccount"
					:show-update="!$route.meta.hideAction"/>
				<CompanyLiteCard
					size="small"
					:show-all-tags="true"
					:loading="pageLoading"
					:company="company"/>
			</div>

			<div class="mylayout-right">
				<template v-if="!$route.meta.hideTab">
					<a-menu
						mode="horizontal"
						type="inner"
						class="mytab-menu"
						:selected-keys="selectedMenu">
						<a-menu-item key="user/base">
							<my-router-link name="user/base" :param-value="$route.params.id">
								{{$t('tab.user_info')}}
							</my-router-link>
						</a-menu-item>
					</a-menu>
					<div class="mytab-content">
						<keep-alive>
							<router-view :user="user" :company="company"></router-view>
						</keep-alive>
					</div>
				</template>
				<template v-else>
					<router-view :user="user" :company="company"></router-view>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import UserCard from "@components/user/UserCard.vue"
import CompanyLiteCard from "@components/company/CompanyLiteCard.vue"
import axios from "axios"
import {Menu} from "ant-design-vue"
export default {
	components : {
		UserCard , CompanyLiteCard ,
		"a-menu" : Menu, "a-menu-item" : Menu.Item ,
	} ,
	mixins : [PageMixin,LoginInfoMixin] ,
	page() {
		return {
			title: this.$t('page.title',{name:this.user.username}),
		}
	} ,
	data() {
		return {
			user : {} ,
			company: {} ,
			lineAccount : {} ,
			selectedMenu : []
		}
	} ,
	watch : {
		$route(newVal) {
			const checkId = newVal.params.id ? newVal.params.id : this.currentUser.id;
			if (!this.user || checkId != this.user.id) {
				this.fetchData()
			}
			this.updateSelectedMenu()
		}
	} ,
	mounted() {
		this.updateSelectedMenu()
		this.fetchData()
	} ,
	methods : {
		updateSelectedMenu() {
			this.selectedMenu = [this.$route.name]
		} ,
		fetchData() {
			const userId = this.$route.params.id ? this.$route.params.id : this.currentUser.id;

			this.showPageLoading()
			this.clearBreadcrumbParams(['companyName','companyId','userName'])
			axios.get("/api/users/"+userId).then((response)=>{
				this.user = response.data.data.user;
				this.company = response.data.data.company;
				if (response.data.data.line_account)
					this.lineAccount = response.data.data.line_account
				else
					this.lineAccount = {}


				this.addBreadcrumbParams({
					companyName : this.company.name ,
					companyId : this.company.id,
					userName : this.user.username
				})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(() => {
				this.hidePageLoading()
			})
		}
	}
}
</script>
<style lang="less" scoped>
.user-card {
	margin-bottom : 8px;
}
.company-card {
	width : auto;
	margin-bottom : 8px;
}
</style>

<i18n locale="th" lang="yaml" >
user.latest_access.tooltip : "เวลาที่เข้าใช้งานในระบบล่าสุด"
user.latest_access.empty : "ยังไม่เคยเข้าใช้งานระบบ"
user.latest_access.prefix : "เข้าใช้งานล่าสุด เมื่อ "
</i18n>

<template>
<a-card :bordered="false" class="user-card mycard mycard-with-details"
	:loading="loading">
	<a-button
		v-if="canUpdate"
		class="mycard-button-action"
		shape="circle"
		icon="edit"
		@click="handleUpdateClick" />

	<div class="mycard-content">
		<div class="mycard-header">
			<ImageLoader
				class="mycard-image"
				:src="user.image_url"
				size="normal"
				border-type="circle">
				<a-icon type="user" />
			</ImageLoader>
			<div class="title">{{user.username}}</div>
			<div v-if="user.user_role" class="subtitle">{{$tenum('user_role', `${user.user_role}`)}}</div>
		</div>
		<ul class="mycard-details">
			<li>
				<a-icon type="user" />
				<span>
					{{user.full_name}}
				</span>
			</li>
			<li>
				<PhoneLink :value="user.phone" />
			</li>
			<template v-if="!isTechnician">
				<li>
					<EmailLink :value="user.email" />
				</li>
				<template v-if="$notEmpty(lineAccount.line_id)">
					<LineProfileCard
						class="user-line-card"
						:line-profile="lineAccount"/>
				</template>
				<li class="separator"></li>

				<li>
					<a-icon type="clock-circle" />
					<DayFromNowText
						:value="user.latest_access_datetime"
						:prefix="$t('user.latest_access.prefix')"
						:additional-tooltip="$t('user.latest_access.tooltip')"/>
					<span v-if="!$notEmpty(user.latest_access_datetime)">
						{{$t('user.latest_access.empty')}}
					</span>
				</li>
			</template>
		</ul>
	</div>
</a-card>
</template>

<script>
import ImageLoader from "@components/common/ImageLoader.vue"
import DayFromNowText from "@components/common/DayFromNowText.vue"
import EmailLink from "@components/common/EmailLink.vue"
import PhoneLink from "@components/common/PhoneLink.vue"
import LineProfileCard from "@components/user/LineProfileCard.vue"

export default {
	components : {
		ImageLoader , EmailLink, PhoneLink, DayFromNowText,
		LineProfileCard ,
	} ,
	props : {
		loading : {
			type : Boolean,
			default: false
		} ,
		user : {
			type : null,
			default : () => []
		} ,
		lineAccount : {
			type : null,
			default : () => []
		} ,
		showUpdate : {
			type: Boolean,
			default: false
		}
	} ,
	computed : {
		isTechnician() {
			const technician = 3
			return this.user.user_role === technician
		} ,
		canUpdate() {
			if (this.$authorize('update','user',{companyId:this.user.company_id}) && this.showUpdate) {
				if (this.$isMe(this.user.id)) {
					return true
				}
				return this.$isAdmin()
			}
			return false
		}
	} ,
	methods : {
		handleUpdateClick() {
			this.$router.push({
					name : 'user/update',
					params : {id : this.user.id}
				});
		} ,
	}
}
</script>

<style lang="less" scoped>
.user-card {
	min-height: 300px;
}
</style>
